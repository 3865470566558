import type { User } from "@prisma/client";
import { useMatches } from "@remix-run/react";
import type { Transition } from "@remix-run/react/dist/transition";
import * as React from "react";
import invariant from "tiny-invariant";

export const MIN_DATA_SIZE_FOR_PAGINATION = 10;
export const CALENDLY_URL = "https://calendly.com/atheva-customer-support";

const DEFAULT_REDIRECT = "/";

const roundingDollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0, // Round to the nearest dollar
});

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param to The redirect destination
 * @param defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT
) {
  if (!to || typeof to !== "string") {
    return defaultRedirect;
  }

  if (!to.startsWith("/") || to.startsWith("//")) {
    return defaultRedirect;
  }

  return to;
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param id The route id
 * @returns The router data or undefined if not found
 */
export function useMatchesData(
  id: string
): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches();
  const route = React.useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  );
  return route?.data;
}

export function isTransitioning(transition: Transition) {
  return ["loading", "submitting"].includes(transition.state);
}

function isUser(user: any): user is User {
  return user && typeof user === "object" && typeof user.email === "string";
}

export function nameForUser(user: User): string {
  return `${user.firstName} ${user.lastName}`;
}

export function useOptionalUser(): User | undefined {
  const data = useMatchesData("root");
  if (!data || !isUser(data.user)) {
    return undefined;
  }
  return data.user;
}

export function useUser(): User {
  const maybeUser = useOptionalUser();
  if (!maybeUser) {
    throw new Error(
      "No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead."
    );
  }
  return maybeUser;
}

/* Validation */

export function isNonEmptyString(input: unknown): input is string {
  return typeof input === "string" && !!input.trim();
}

export function isValidEmail(email: unknown): email is string {
  return isNonEmptyString(email) && Boolean(email.match(/^\S+@\S+\.\S+$/));
}

export function validateRequiredStringField(input: unknown, fieldName: string) {
  if (!isNonEmptyString(input)) return `${fieldName} is required`;
  else return null;
}

/* Formatters */

export function formatDollarsWithCents(
  cents: number | bigint,
  maximumFractionDigits = 2
) {
  const dollarWithCentsFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits,
  });
  const dollars = Number(cents) / 100;

  return dollarWithCentsFormatter.format(dollars);
}

export function formatDollarsWithoutCents(cents: number | bigint) {
  const dollars = Number(cents) / 100;
  return roundingDollarFormatter.format(dollars);
}

/** getFormattedBid formats the bid as $X.XX/dollar = $Y,YYY */
export const getFormattedBid = (
  creditAmountCents: number | bigint,
  bidAmountCents: number | bigint
) => {
  const centsOnDollar = Number(creditAmountCents)
    ? (Number(bidAmountCents) / Number(creditAmountCents)) * 100
    : 0;
  const centsOnDollarFormatted =
    formatDollarsWithCents(centsOnDollar) + "/dollar";
  const bidAmountFormatted = formatDollarsWithoutCents(Number(bidAmountCents));
  return `${centsOnDollarFormatted} = ${bidAmountFormatted}`;
};

export function decimalPlaceCount(num: number) {
  if (Number.isInteger(num)) {
    return 0;
  } else {
    return num.toString().split(".")[1].length;
  }
}

/* DOM */

function getAncestorElements(elem: HTMLElement) {
  const retVal: HTMLElement[] = [];

  let current: HTMLElement | null = elem;
  while (current) {
    retVal.push(current);
    current = current.parentElement;
  }

  return retVal;
}

/**
 * Say we have a table row with a click handler, and dropdown menu overlaid on it,
 * we may want to not invoke the row click handler if a dropdown item was clicked.
 * This function returns true if the given elem is a common click target like a button.
 */
export function isCommonClickTarget(elem: HTMLElement) {
  const ancestors = getAncestorElements(elem);
  const commonClickTargetNodeNames = ["a", "button"];
  const commonClickTargetClassNames = ["ant-dropdown-menu-item"];

  if (
    ancestors.some(({ nodeName }) =>
      commonClickTargetNodeNames.includes(nodeName.toLocaleLowerCase())
    )
  )
    return true;

  if (
    ancestors.some(({ classList }) =>
      [...classList].some((clasz) =>
        commonClickTargetClassNames.includes(clasz)
      )
    )
  )
    return true;

  return false;
}

/* Other */

export const calculateAthevaFee = (amountCents: number | bigint) => {
  return calculateAthevaFee_promotional(amountCents);
};

export const range = (count: number, oneIndexed: boolean = false) => {
  if (count <= 0) return [];

  const range = [...Array(count).keys()];
  if (oneIndexed) {
    range.shift();
    range.push(count);
  }
  return range;
};

// Exported for testability
export const calculateAthevaFee_promotional = (
  amountCents: number | bigint
) => {
  const rate = 1 / 100;
  let amountToBeProcessed = Number(amountCents);
  return Math.round(amountToBeProcessed * rate);
};

// Exported for testability
export const calculateAthevaFee_bracketed = (amountCents: number | bigint) => {
  const brackets = [
    // [lowerBoundExclusive, rate]
    [500_000_00, 1 / 100],
    [100_000_00, 2.5 / 100],
    [0, 5 / 100],
  ];
  let amountToBeProcessed = Number(amountCents);
  let fee = 0;

  for (const [lb, rate] of brackets) {
    const amountInBracket = Math.max(amountToBeProcessed - lb, 0);
    fee += Math.round(amountInBracket * rate);
    amountToBeProcessed -= amountInBracket;
  }

  invariant(amountToBeProcessed === 0);

  return fee;
};

export const waitUntilConditionMet = (
  conditionFn: () => boolean,
  pollIntervalMs: number
) => {
  const poll = (resolve: any) => {
    if (conditionFn()) resolve();
    else setTimeout(() => poll(resolve), pollIntervalMs);
  };
  return new Promise(poll);
};
